import { Component, render, $ } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import RewardsDropsCard from 'Component/Rewards/DropsCard/RewardsDropsCard'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import rewardsDrops from 'Common/Service/Rewards/Drops/RewardsDrops'
import markdown from '@riotgames/markdown-parser'
import locale from 'Common/Locale/Locale'
import google from '../../../Common/Service/Google/Google'
import riotBar from '../../../Common/Service/RiotBar/RiotBar'
import analytics from 'Common/Service/Analytics/Analytics'

// Server is returning escaped linebreaks instead of linebreaks
// TODO: remove when/if server fixes it
const escapedLineBreakRegExp = /\\n/g
const lineBreak = '\n'
const codeRegExp = /{code}/g
const minFansUnlocked = 1000
const fallbackLocale = 'en_US' // service locale, not ours
const DropTypes = {
  PSA: 'psa',
  CODE: 'code',
  TEXT: 'text'
}

const hasInstructionDropTypes = [DropTypes.CODE, DropTypes.TEXT]

const promoCodePending = 'pending'

class RewardsDropsOverlay extends Component {
  constructor (props) {
    super(props)

    this.state = {
      drop: null,
      error: null,
      showShareableImageButton: false
    }

    this.doesSharableImageExistWithRetry = this.doesSharableImageExistWithRetry.bind(
      this
    )
    this.fetchImage = this.fetchImage.bind(this)
  }

  // called externally
  static display (id) {
    render(<RewardsDropsOverlay id={ id }/>, document.body)
  }

  renderPresentedBy (image) {
    return (
      <div class="presented-by">
        <div class="label">{ locale.translate('rewards.drops.presentedBy') }</div>
        <Image class="img" src={ image } size="x54"/>
      </div>
    )
  }

  toggleInstructions (event) {
    event.preventDefault()
    event.stopPropagation()
    const $instructions = $(event.currentTarget)
      .parent()
      .find('.instructions')

    $instructions.toggleClass('show')
  }

  hasInstructions (dropType) {
    return hasInstructionDropTypes.includes(dropType)
  }

  componentDidUpdate () {
    $('.markdown a').attr('target', '_blank')
  }

  renderReward (reward) {
    // psa type drop has no how to claim button - except regionless accounts
    if (reward.localizedInventory.inventory.dropType === DropTypes.PSA) {
      return this.renderGameItemReward(reward)
    }

    return this.renderTextOrPromoCodeReward(reward)
  }

  renderGameItemReward (reward) {
    return (
      <div class="reward" role="button">
        <div class="wrapper">
          <div class="title">
            { this.getLocalizedTitle(reward.localizedInventory) }
          </div>
          { this.renderDescription(
            this.getLocalizedDescription(reward.localizedInventory)
          ) }
        </div>
        <div class="image">
          <Image
            class="img"
            src={ reward.localizedInventory.inventory.imageUrl }
            size="240x272"
          />
        </div>
      </div>
    )
  }

  renderTextOrPromoCodeReward (reward) {
    // text and code types as well as regionless accounts have how to claim button
    return (
      <div
        class="reward"
        onclick={ (event) => this.toggleInstructions(event) }
        role="button">
        <div class="wrapper">
          <div class="title">
            { this.getLocalizedTitle(reward.localizedInventory) }
          </div>
          <div class="how-to-claim">
            { locale.translate('rewards.drops.howToClaim') }
          </div>
        </div>
        <div class="image">
          <Image
            class="img"
            src={ reward.localizedInventory.inventory.imageUrl }
            size="240x272"
          />
        </div>
      </div>
    )
  }

  isPromoCodePending (promoCode) {
    return promoCode && promoCode.toLowerCase() === promoCodePending
  }

  insertPromoCode (description, promoCode) {
    return description.replace(codeRegExp, promoCode || '')
  }

  renderDescription (description, promoCode = '') {
    if (this.isPromoCodePending(promoCode)) {
      return (
        <div class="description">
          { locale.translate('rewards.drops.checkBackSoon') }
        </div>
      )
    }

    const markdownOptions = { useInlineLinks: true }
    const descriptionWithPromoCode = this.insertPromoCode(
      description.replace(escapedLineBreakRegExp, lineBreak),
      promoCode
    )

    return (
      <div
        class="description markdown"
        dangerouslySetInnerHTML={ {
          __html: markdown.parse(descriptionWithPromoCode, markdownOptions)
        } }
      />
    )
  }

  isValidInventoryItem (item) {
    return (
      item
      && item.inventory
      && item.inventory.imageUrl
      && item.inventory.dropType
      && item.title
      && this.getLocalizedTitle(item)
      && item.description
      && this.getLocalizedDescription(item)
    )
  }

  getLocalizedTitle (item) {
    const loc = locale.get(true)

    return item.title[loc] || item.title[fallbackLocale]
  }

  getLocalizedDescription (item) {
    const loc = locale.get(true)

    return item.description[loc] || item.description[fallbackLocale]
  }

  renderInventory (inventory) {
    if (!inventory) return // inventory/reward is optional
    const items = inventory.map((item) => {
      if (!this.isValidInventoryItem(item.localizedInventory)) return

      return (
        <div class="wrapper">
          <div class="item">
            { this.renderReward(item) }
            { this.hasInstructions(item.localizedInventory.inventory.dropType)
              && this.renderInstructions(item) }
          </div>
        </div>
      )
    })

    return <div class="inventory">{ items }</div>
  }

  renderInstructions (item) {
    const description = this.getLocalizedDescription(item.localizedInventory)

    return (
      <div class="instructions">
        <div class="how-to-claim-title">
          { locale.translate('rewards.drops.howToClaim') }
        </div>
        { this.renderDescription(description, item.promoCode) }
      </div>
    )
  }

  fetchDetails (id) {
    return rewardsDrops
      .fetchDetails(id)
      .then((drop) => {
        this.fetchImage(drop.triggerID, drop.dropID)
        this.setState({ drop })
        analytics.trackEvent('drop_overlay_opened', {
          dropId: drop.dropID,
          title: drop.dropsetTitle,
          triggerId: drop.triggerID
        })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  close () {
    $(document.body).removeClass('no-scroll')
    // prevent firefox from rendering previous content for a split second
    // this.base can be undefined if drops overlay is open
    // and user navigates away (click on a top menu item)
    $(this.base).html('')
    render(null, document.body, this.base) // destroy
  }

  addListeners () {
    document.addEventListener(
      'keydown',
      this.keyHandler = (event) => {
        if (event.code === 'Escape') this.close()
      }
    )

    document.addEventListener('router.navigation.go', () => this.close())
  }

  doesSharableImageExistWithRetry (
    triggerId,
    dropId,
    getWaitTimeMilliseconds = () => 1000,
    numberOfRetries = 10
  ) {
    return rewardsDrops
      .fetchDoesShareableDropImageExist(triggerId, dropId)
      .catch((e) => {
        if (numberOfRetries === 1) {
          throw e
        }

        return new Promise((resolve) =>
          setTimeout(resolve, getWaitTimeMilliseconds())
        ).then(() =>
          this.doesSharableImageExistWithRetry(
            triggerId,
            dropId,
            getWaitTimeMilliseconds,
            numberOfRetries - 1
          )
        )
      })
  }

  fetchImage (triggerId, dropId) {
    // Keep retrying 3 times, 5 seconds in between
    return this.doesSharableImageExistWithRetry(
      triggerId,
      dropId,
      () => 5000,
      3
    ).then((doesExist) => {
      if (doesExist) this.setState({ showShareableImageButton: true })
      return doesExist
    })
  }

  componentDidMount () {
    this.fetchDetails(this.props.id)
    this.addListeners()
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.keyHandler)
  }

  renderClose () {
    return (
      <div class="close" onclick={ (event) => this.close(event) }>
        <Icon name="close"/>
      </div>
    )
  }

  renderErrorOrLoading (error) {
    if (error) {
      const element = document.querySelector('.InformBubble')
      const dismiss = () => render(null, document.body, element)

      dismiss() // prevent showing multiple bubbles

      return (
        <InformBubble theme="error" icon="error" onDismissHandler={ dismiss }>
          { error.message }
        </InformBubble>
      )
    }

    // loading needs to happen inside of the overlay
    return (
      <div class={ this.constructor.name }>
        <div class="background"/>
        { this.renderClose() }
        <InformLoading/>
      </div>
    )
  }

  renderFansUnlocked (isCappedDrop, numberOfFansUnlocked = 0) {
    const shouldRender = isCappedDrop || numberOfFansUnlocked >= minFansUnlocked

    let translation = ''

    if (shouldRender) {
      const formattedCount = {
        number: locale.shortNumberFormatter(numberOfFansUnlocked)
      }

      const translationKey = isCappedDrop
        ? 'rewards.drops.cappedOnly.fansUnlocked'
        : 'rewards.drops.fansUnlocked'

      translation = locale.translate(translationKey, formattedCount)
    }

    return <div class="fans-unlocked">{ translation }</div>
  }

  renderDropsetDescription (description) {
    return description && <div class="dropset-description">{ description }</div>
  }

  handleShareClick (href) {
    // TODO: should refactor this into generic analytics wrapper
    google.pushToDataLayer({
      event: 'Share Drops Card',
      eventCategory: 'Drops',
      dropId: this.state.dropID,
      triggerId: this.state.triggerID,
      puuid: riotBar.getPuuid()
    })
    analytics.trackEvent('drop_shared', {
      dropId: this.state.dropID,
      title: this.state.drop.dropsetTitle,
      rare: this.state.drop.rarity && this.state.drop.rarity.rare,
      triggerId: this.state.triggerID
    })
    window.window.open(href, '_blank', 'noopener noreferrer')
  }

  renderDownloadShareableImageButton () {
    const { drop } = this.state
    const url = rewardsDrops.getShareableDropImageUrl(
      drop.triggerID,
      drop.dropID
    )

    return (
      <a
        class="shareDropsButton"
        onClick={ () => this.handleShareClick(url) }
        rel="noopener noreferrer"
        target="_blank">
        <Icon name="share"/>
        <span>{ locale.translate('rewards.drops.share') }</span>
      </a>
    )
  }

  render () {
    const { drop, error } = this.state

    if (!this.props.id || !drop || error) {
      return this.renderErrorOrLoading(error)
    }

    $(document.body).addClass('no-scroll')

    return (
      <div class={ this.constructor.name }>
        <div class="background"/>
        { this.renderClose() }

        <div
          role="button"
          class="content"
          onclick={ (event) =>
            $(event.target).is('.content') && this.close(event)
          }>
          { this.renderPresentedBy(drop.sponsorImages.presentedByUrl) }
          { this.renderFansUnlocked(drop.cappedDrop, drop.numberOfFansUnlocked) }
          <div class="background-title-wrapper">
            <div class="background-title">{ drop.dropsetTitle }</div>
          </div>
          <RewardsDropsCard
            id={ drop.dropID }
            classList="overlay"
            productImage={ drop.dropsetImages.cardUrl }
            sponsorImage={ drop.sponsorImages.cardOverlayUrl }
            backgroundColor={ drop.rarity && drop.rarity.badgeBackgroundColor }
            foregroundColor={ drop.rarity && drop.rarity.badgeForegroundColor }
            unlockedDate={ drop.unlockedDateMillis }
            title={ drop.dropsetTitle }
            isRare={ drop.rarity && drop.rarity.rare }
            styleDeclaration={ drop.rarity && drop.rarity.styleDeclaration }
          />
          { this.renderDropsetDescription(drop.dropsetDescription) }
          { this.state.showShareableImageButton
            && this.renderDownloadShareableImageButton() }
          { this.renderInventory(drop.inventory) }
        </div>
      </div>
    )
  }
}

export default RewardsDropsOverlay
