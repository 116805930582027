import { Component } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'

class RewardsStartEarning extends Component {
  render () {
    const { actionOnClickHandler } = this.props

    return (
      <div class={ util.classNames(this.constructor.name) }>
        <div class="text">
          <div class="title">{ locale.translate('rewards.loggedOut.title') }</div>
          <div class="body">
            { locale.translate('rewards.loggedOut.body') }
            { Icon.unicode('space') }
            <a
              class="link"
              href="https://leagueoflegends.com/legal/privacy"
              target="_blank">
              { locale.translate('rewards.privacyPolicy') }
            </a>
          </div>
          <div class="action-container">
            <div
              class="action login"
              onclick={ actionOnClickHandler }
              role="button">
              { locale.translate(
                this.props.loggedIn ? 'rewards.optIn' : 'rewards.logAndOptIn'
              ) }
            </div>
          </div>
        </div>
        <div class="image">
          <Image src="/rewards/hextech-chest.png" size="668x558"/>
        </div>
      </div>
    )
  }
}

export default RewardsStartEarning
