import { Component } from 'lib'
import Image from 'Component/Asset/Image/Image'
import RewardsDropsOverlay from 'Component/Rewards/DropsOverlay/RewardsDropsOverlay'
import rewardsDrops from 'Common/Service/Rewards/Drops/RewardsDrops'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import analytics from 'Common/Service/Analytics/Analytics'

class RewardsDropsCard extends Component {
  constructor (props) {
    super(props)

    this.container = null
    this.card = null
    this.gradient = null
  }

  componentDidMount () {
    this.setupForAnimationEvents()
    this.addAnimationEvents()
  }

  componentDidUpdate () {
    this.setupForAnimationEvents()
  }

  addAnimationEvents () {
    this.container.addEventListener('mouseenter', () => this.onMouseEnter())
    this.container.addEventListener('mouseleave', () => this.onMouseLeave())
  }

  displayOverlay (id, event) {
    event.preventDefault()
    event.stopPropagation()
    analytics.trackEvent('drop_viewed')

    RewardsDropsOverlay.display(id)
  }

  onMouseEnter () {
    // This is the cross-browser (for IE9) solution for adding a class
    const names = this.gradient.className.split(' ')

    if (names.indexOf('gradient-over') === -1) {
      this.gradient.className += ' ' + 'gradient-over'
    }
    this.cardRotator = (event) => this.rotateCard(event)
    this.container.style.transform = 'scale3d(1.03, 1.03, 1.03)'
    this.container.addEventListener('mousemove', this.cardRotator)
  }

  onMouseLeave () {
    // This is the cross-browser (for IE9) solution for removing a class
    this.gradient.className.replace(/\bgradient-over\b/g, '')
    this.container.removeEventListener('mousemove', this.cardRotator)
    this.container.style.transform = ''
    this.card.style.transition = ''
    this.card.style.transform = ''
    this.gradient.style.transition = ''
    this.gradient.style.top = ''
    this.gradient.style.left = ''
  }

  rotateCard (event) {
    const bounds = this.card.getBoundingClientRect()
    const mouseX = event.clientX
    const mouseY = event.clientY
    const leftX = mouseX - bounds.x
    const topY = mouseY - bounds.y
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2
    }
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2)

    this.card.style.transition = 'transform 0.1s ease-out'
    this.card.style.perspecitve = `${bounds.width * 3}px`
    this.card.style.transform = `
        scale3d(1.03, 1.03, 1.03)
        rotate3d(
          ${-center.y / 100},
          ${center.x / 100},
          0,
          ${Math.log(distance) * 2}deg
        )
      `

    const mouseProgressX = bounds.width - leftX || 0
    const mouseProgressY = bounds.height - topY || 0

    this.gradient.style.transition = 'none'
    this.gradient.style.top = `${-100
      + mouseProgressY / bounds.height * 100}%`
    this.gradient.style.left = `${-100
      + mouseProgressX / bounds.width * 100}%`
  }

  setupForAnimationEvents () {
    this.container = this.base
    this.card = this.container.querySelector('.card')
    this.gradient = this.container.querySelector('.gradient')
  }

  render (props) {
    const {
      id,
      classList,
      productImage,
      sponsorImage,
      unlockedDate,
      title,
      isRare,
      styleDeclaration: gradientStyle
    } = props

    const classNames = util.classNames(classList, 'card')
    const date = locale.translate(unlockedDate).shortDayAndMonth
    const { highContrastColor, overlayColor } = rewardsDrops.getColorsForRarity(
      gradientStyle
    )

    // reduce font-size for longer titles
    const titleClassNames = util.classNames(
      'title',
      title.length >= 35 ? 'long' : 'short'
    )

    return (
      <div class={ this.constructor.name }>
        <div
          role="button"
          key={ id }
          class={ classNames }
          onClick={ (event) => this.displayOverlay(id, event) }>
          <div class="gradient" style={ gradientStyle }/>
          <div class="sponsor-image">
            <Image class="img" src={ sponsorImage } size="x40"/>
          </div>
          <div class="product-image">
            { productImage.endsWith('.gif')
              ? <Image class="img" src={ productImage } size="original"/>
              : <Image class="img" src={ productImage } size="384x200"/>
            }
          </div>
          { isRare && (
            <div
              class="rare-badge"
              style={ {
                backgroundColor: highContrastColor,
                color: overlayColor
              } }>
              { locale.translate('rewards.drops.rare') }
            </div>
          ) }
          <div class="unlocked-date" style={ { color: highContrastColor } }>
            { locale.translate('rewards.drops.unlockedDate', { date }) }
          </div>
          <div class={ titleClassNames } style={ { color: highContrastColor } }>
            { title }
          </div>
        </div>
      </div>
    )
  }
}

export default RewardsDropsCard
