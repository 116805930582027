import { $, Component, render } from 'lib'
import locale from 'Common/Locale/Locale'
import Icon from 'Component/Asset/Icon/Icon'
import riotBar from 'Common/Service/RiotBar/RiotBar'

class LogInReminder extends Component {
  constructor () {
    super()
    this.close = this.close.bind(this)
  }

  close () {
    window.sessionStorage.setItem('loginDismissed', 'true')
    $(this.base).html('')
    render(null, document.body, this.base)
  }

  render () {
    return (
      <div class="LogInReminder">
        <div class="bar">
          <div class="message">
            { locale.translate('rewards.status.earn', {
              action: (
                <a
                  onclick={ () => riotBar.login(window.location.href) }
                  class="link">
                  { locale.translate('rewards.status.login') }
                </a>
              )
            }) }
          </div>
          <div class="close" onClick={ this.close }>
            <Icon name="close"/>
          </div>
        </div>
      </div>
    )
  }
}

export default LogInReminder
