import { Component } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import InformPassRewards from 'Component/Inform/PassRewards/InformPassRewards'

// TODO Fix this. This should not be hardcoded
const TOTAL_MISSIONS = 8
const WRAPPER_MISSION_INDICES = [1, 3, 6]
const MASTERWORK_MISSION_INDICES = [4, 8]

class RewardsAchievement extends Component {
  renderWrapperIcon (index, completed) {
    const wrapperClass = util.classNames(
      'wrapper-image',
      index > completed && 'wrapper-incomplete'
    )
    const diamondClass = util.classNames(
      'diamond',
      index > completed && 'achievement-incomplete'
    )
    const wrapperIndex = WRAPPER_MISSION_INDICES.indexOf(index)
    const diamondIndex = MASTERWORK_MISSION_INDICES.indexOf(index)

    if (wrapperIndex > -1 || diamondIndex > -1) {
      return (
        <div class="achievement-accents">
          { wrapperIndex > -1 && (
            <div class={ wrapperClass }>
              <Icon name="passWrapper" wrapperLevel={ wrapperIndex }/>
            </div>
          ) }
          <div class={ diamondClass }/>
        </div>
      )
    }
  }

  renderAchievementLevelProgression (completed) {
    const achievementLevels = []

    for (let i = 1; i <= TOTAL_MISSIONS; i++) {
      const achievementProgressionClass = util.classNames(
        'achievement-progress',
        i > completed && 'achievement-incomplete'
      )

      achievementLevels.push(
        <div class="achievement-unit">
          <div class={ achievementProgressionClass }/>
          { this.renderWrapperIcon(i, completed) }
        </div>
      )
    }
    return <div class="achievement">{ achievementLevels }</div>
  }

  calculateTimelineTitle (allComplete) {
    const { selectedPass, isTeamPass } = this.props

    if (isTeamPass) {
      const translation = allComplete
        ? 'rewards.teamPass.passTitleComplete'
        : 'rewards.teamPass.passTitle'

      return locale.translate(translation, { league: selectedPass.name })
    }
    else {
      const translation = allComplete
        ? 'rewards.passes.fanPassComplete'
        : 'rewards.passes.fanPass'

      return locale.translate(translation)
    }
  }

  calculateTimelineDescription (allComplete) {
    const { selectedPass, isTeamPass } = this.props
    const date = locale.date(this.props.missionsExpiry)

    let timelineDescription

    if (allComplete) {
      timelineDescription = locale.translate('rewards.passes.passComplete')
    }
    else {
      timelineDescription = isTeamPass
        ? locale.translate('rewards.teamPass.passDescription', {
          league: selectedPass.name,
          time: date.hourAndMinute,
          date: date.dayAndMonth
        })
        : locale.translate('rewards.fanPass.passDescription', {
          time: date.hourAndMinute,
          date: date.dayAndMonth
        })
    }
    return timelineDescription
  }

  render () {
    const { selectedPass, isTeamPass } = this.props
    const missionCompleteCount
      = selectedPass.passMissions.progression.missionsCompleted
    const allComplete = missionCompleteCount === TOTAL_MISSIONS
    const timelineTitle = this.calculateTimelineTitle(allComplete)
    const timelineDescription = this.calculateTimelineDescription(allComplete)
    const passType = isTeamPass ? selectedPass.type : 'fan'

    return (
      <div class={ this.constructor.name }>
        <div class="achievement-title">{ timelineTitle }</div>
        <div class="achievement-description">
          <p>
            { timelineDescription }
            <InformPassRewards passType={ passType }/>
          </p>
        </div>
        { this.renderAchievementLevelProgression(missionCompleteCount) }
        <div class="mission-summation">
          { locale.translate('rewards.missionsCompleted.outOf', {
            completed: missionCompleteCount,
            total: TOTAL_MISSIONS
          }) }
        </div>
      </div>
    )
  }
}

export default RewardsAchievement
