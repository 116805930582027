import { Component } from 'lib'
import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'

class RewardsPassDescription extends Component {
  renderIntroduction () {
    return (
      <div class="introduction">
        { locale.translate('rewards.passes.introducing', {
          pass: (
            <span class="pass">
              { locale.translate(
                this.props.isTeamPass
                  ? 'rewards.passes.teamPass'
                  : 'rewards.passes.fanPass'
              ) }
            </span>
          )
        }) }
      </div>
    )
  }

  renderImage () {
    const imageSrc = this.props.isTeamPass
      ? '/rewards/pass/team-pass-store.png'
      : '/rewards/pass/fan-pass-store.png'

    return (
      <div class="message-image">
        <Image class="helper-image" src={ imageSrc } size="616x438"/>
      </div>
    )
  }

  renderWardSkinEmotes () {
    const emoteImage = this.props.isTeamPass
      ? '/rewards/pass/team-pass-emote.png'
      : '/rewards/pass/fan-pass-emote.png'

    return (
      <div class="message-image">
        <Image class="helper-image" src={ emoteImage } size="626x324"/>
      </div>
    )
  }

  renderChroma () {
    const chromaImage = this.props.isTeamPass
      ? '/rewards/pass/team-dragonslayer-braum.png'
      : '/rewards/pass/fan-dragonslayer-braum.png'
    const imgClass = this.props.isTeamPass
      ? 'helper-image'
      : 'smaller-helper-image'

    return (
      <div class="message-image">
        <Image class={ imgClass } src={ chromaImage } size="616x374"/>
      </div>
    )
  }

  renderIcon () {
    const firstImageSrc = this.props.isTeamPass
      ? '/rewards/pass/icon-level-one.png'
      : '/rewards/pass/icon-pro-poro.png'

    const secondImageSrc = this.props.isTeamPass
      ? '/rewards/pass/icon-level-two.png'
      : '/rewards/pass/icon-screemo.png'

    const firstImageTitle = this.props.isTeamPass
      ? locale.translate('rewards.passes.levels', { number: 1 })
      : locale.translate('rewards.fanPass.levelOneDescription')

    const secondImageTitle = this.props.isTeamPass
      ? locale.translate('rewards.passes.levels', { number: 2 })
      : locale.translate('rewards.fanPass.levelTwoDescription')

    return (
      <div class="message-image center-flex">
        <div class="icon-padded">
          <Image class="icon-image" src={ firstImageSrc } size="200x200"/>
          <div class="icon-title">{ firstImageTitle }</div>
        </div>
        <div class="icon-info">
          <Image class="icon-image" src={ secondImageSrc } size="200x200"/>
          <div class="icon-title">{ secondImageTitle }</div>
        </div>
      </div>
    )
  }

  renderHeadline (locKey) {
    return <div class="headline">{ locale.translate(locKey) }</div>
  }

  renderDetails (locKey) {
    return <div class="details">{ locale.translate(locKey) }</div>
  }

  render () {
    const passType = this.props.isTeamPass ? 'teamPass' : 'fanPass'

    return (
      <div class={ this.constructor.name }>
        <div class="message">
          <div class="message-text">
            <div class="header">{ locale.translate('rewards.passes.title') }</div>
            <div class="details">
              { locale.translate(
                this.props.isTeamPass
                  ? 'rewards.passes.teamDetails'
                  : 'rewards.passes.fanDetails'
              ) }
            </div>
            <div class="rule"/>
            { this.renderHeadline('rewards.' + passType + '.levelup') }
            { this.renderDetails('rewards.' + passType + '.levelDetails') }
            { this.renderIcon() }
            { this.renderHeadline('rewards.' + passType + '.passRewards') }
            { this.renderDetails('rewards.' + passType + '.rewardDetails') }
            { this.renderWardSkinEmotes() }
            { this.renderHeadline('rewards.passes.chromaReward') }
            { this.renderDetails('rewards.' + passType + '.chromaDetails') }
            { this.renderChroma() }
            <div class="rule"/>
            { this.renderHeadline('rewards.passes.buy') }
            { this.renderDetails('rewards.passes.start') }
            { this.renderImage() }
          </div>
        </div>
      </div>
    )
  }
}
export default RewardsPassDescription
