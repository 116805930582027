import { Component } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'

class RewardsPassSummary extends Component {
  renderDescription (selected, activePass, columnItem, expanded) {
    const missionCompleteCount
      = util.getDeepValue(
        activePass,
        'passMissions.progression.missionsCompleted'
      ) || 0
    const totalMissionCount
      = util.getDeepValue(activePass, 'passMissions.progression.totalMissions')
      || 0
    const isComplete
      = totalMissionCount > 0 && missionCompleteCount === totalMissionCount
    const missionsInfo = isComplete
      ? locale.translate('rewards.missionsCompleted')
      : locale.translate('rewards.passes.levels', {
        number: activePass.passMissions.progression.level
      })

    return (
      <div class="pass-description">
        <img
          class={ util.classNames('pass-icon', selected && 'selected') }
          src={ activePass.iconUrl }
        />
        <div class="pass-details">
          <div class={ util.classNames('title', selected && 'selected') }>
            { activePass.name }
          </div>
          <div
            class={ util.classNames(
              'missions-info',
              isComplete && 'missions-complete'
            ) }>
            { missionsInfo }
          </div>
        </div>
        { !columnItem && (
          <div class="arrow">
            <Icon name="arrow" direction={ expanded ? 'up' : 'down' }/>
          </div>
        ) }
      </div>
    )
  }

  isPassCompleted (achievementLevels) {
    return !achievementLevels.find(
      (achievementLevel) => achievementLevel.status !== 'complete'
    )
  }

  render () {
    const {
      columnItem,
      activePass,
      onPassSelectedHandler,
      selected,
      expanded
    } = this.props

    const classNames = util.classNames(
      this.constructor.name,
      columnItem && 'column-item',
      selected && 'selected',
      expanded && 'expanded'
    )

    return (
      <div
        class={ classNames }
        onclick={ () => onPassSelectedHandler(activePass) }
        role="button">
        <div class="pass-layout">
          { this.renderDescription(selected, activePass, columnItem, expanded) }
        </div>
      </div>
    )
  }
}

export default RewardsPassSummary
