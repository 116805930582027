import { Component } from 'lib'
import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'

class RewardsPassAvailabilityContent extends Component {
  renderIntroduction () {
    return (
      <div class="introduction">
        { locale.translate('rewards.passes.introducing', {
          pass: (
            <span class="pass">
              { locale.translate(
                this.props.isTeamPass
                  ? 'rewards.passes.teamPass'
                  : 'rewards.passes.fanPass'
              ) }
            </span>
          )
        }) }
      </div>
    )
  }

  renderImage () {
    const imageSrc = this.props.isTeamPass
      ? '/rewards/pass/team-pass-rewards.png'
      : '/rewards/pass/fan-pass-rewards.png'

    return (
      <div class="message-image">
        <Image class="helper-image" src={ imageSrc } size="730x438"/>
      </div>
    )
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        <div class="message">
          <div class="message-text">
            <div class="header">
              { this.renderIntroduction() }
              { locale.translate('rewards.passes.title') }
            </div>
            <div class="headline">
              { locale.translate('rewards.passes.unlock') }
            </div>
            <div class="details">
              { locale.translate(
                this.props.isTeamPass
                  ? 'rewards.passes.teamDetails'
                  : 'rewards.passes.fanDetails'
              ) }
            </div>
            { this.renderImage() }
            <div class="rule"/>
            <div class="headline">{ locale.translate('rewards.passes.buy') }</div>
            <div class="details">
              { locale.translate('rewards.passes.start') }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default RewardsPassAvailabilityContent
