/* eslint max-lines: ["error", {"max": 300, "skipBlankLines": true, "skipComments": true}] */
import { Component, Logger, store } from 'lib'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import dataDragon from 'Common/Service/DataDragon/DataDragon'

const missionWarningTime = util.times.DAY
const hideMissionTimeLeft = 365 * util.times.DAY

class RewardsMission extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    store.onChange(
      'dataDragon.setup',
      this.onDataDragonSetup = (setup) => {
        if (setup) {
          this.getIconUrl()
        }
      }
    )
  }

  componentDidMount () {
    dataDragon.setup()
    this.renderDescription()
  }

  componentDidUpdate (prevProps) {
    this.renderDescription()
    if (
      util.getDeepValue(this.props, 'item.iconUrl')
      !== util.getDeepValue(prevProps, 'item.iconUrl')
    ) {
      this.onDataDragonSetup(store.get('dataDragon.setup'))
    }
  }

  componentWillUnmount () {
    store.removeListener('dataDragon.setup', this.onDataDragonSetup)
    store.set('dataDragon.setup', false)
  }

  // Description may come from the server as HTML
  // This is very dangerous, and screws with React's diffing algorithms
  // with the virtual DOM. Updates should not modify this Component's
  // DOM structure on the same level as description
  renderDescription () {
    const objectives = util.getDeepValue(this.props, 'item.objectives')

    if (objectives) {
      // Render multiple descriptions
      const descriptions = this.base.querySelectorAll('.description')

      for (let i = 0; i < descriptions.length; i++) {
        descriptions[i].innerHTML = objectives[i].description[locale.get(true)]
      }
    }
    // TODO: Remove this backwards compatible code when Raptor goes into prod
    else {
      this.base.querySelector(
        '.description'
      ).innerHTML = this.props.item.description[locale.get(true)]
    }
  }

  renderRewards (rewards, renderCompleted) {
    const loc = locale.get(true)
    const classNames = util.classNames(
      renderCompleted && 'reward-completed',
      'rewards'
    )

    return (
      <div class={ classNames }>
        { rewards.map((reward) => (
          <div class="reward">
            <div class="reward-description">{ reward.description[loc] }</div>
          </div>
        )) }
      </div>
    )
  }

  renderProgressBar (completed, completedSteps, remainingSteps) {
    if (completed) {
      return <div class="completed-progress progress-bar"/>
    }

    const progress = completedSteps * 100 / (completedSteps + remainingSteps)

    const progressClassNames = util.classNames(
      progress === 0 && 'no-progress',
      'mission-progress'
    )

    return (
      <div class="active-progress progress-bar">
        <div class={ progressClassNames } style={ 'width: ' + progress + '%' }/>
      </div>
    )
  }

  renderTimeLeft (item) {
    if (item.remainingMillis > hideMissionTimeLeft) {
      return
    }

    const localeKeyToTimeUnit = {
      'date.daysLeft': util.times.DAY,
      'date.hoursLeft': util.times.HOUR,
      'date.minutesLeft': util.times.MINUTE
    }

    let timeLeft, timeText

    Object.keys(localeKeyToTimeUnit).some((localeKey) => {
      timeLeft = item.remainingMillis / localeKeyToTimeUnit[localeKey]
      timeText = locale.translate(localeKey, { time: Math.ceil(timeLeft) })
      if (timeLeft > 1) {
        return true
      }
    })

    const missionClass = util.classNames(
      'timeleft',
      item.remainingMillis < missionWarningTime && 'red-warning'
    )

    return <div class={ missionClass }>{ timeText }</div>
  }

  renderProgress (completedSteps, remainingSteps) {
    return (
      <div class="progress">
        { completedSteps } / { completedSteps + remainingSteps }
      </div>
    )
  }

  getAnimateClass (mission, completed, renderCompleted) {
    if (completed && mission.celebrationAnimationCompleted) {
      return renderCompleted ? 'open' : 'close'
    }
  }

  getIconUrl () {
    const lcuPath = util.getDeepValue(this.props, 'item.iconUrl')

    if (!lcuPath) {
      this.log.warn('LCU path not defined', lcuPath)
      return
    }

    const fileName = lcuPath.split('/').pop()

    // e.g. '/lol-game-data/assets/ASSETS/Missions/watch-em.png'
    if (lcuPath.indexOf('/Missions/') > -1) {
      return this.setState({ iconUrl: dataDragon.getMissionImage(fileName) })
    }

    // e.g. '/lol-game-data/assets/v1/profile-icons/3622.jpg'
    if (lcuPath.indexOf('/profile-icons/') > -1) {
      const id = fileName.split('.')[0]

      return this.setState({ iconUrl: dataDragon.getProfileIcon(id) })
    }

    this.log.warn('Could not get icon URL from LCU path', lcuPath)
    this.setFallbackIcon()
  }

  setFallbackIcon () {
    this.log.warn('Current icon failed to load', this.state.iconUrl)
    this.setState({
      fallback: true,
      iconUrl: undefined
    })
  }

  renderConnective (completionExpression, objectiveSequence, isLastObjective) {
    if (isLastObjective) {
      return // nothing
    }

    // Default to 'and', which is how an empty completionExpression is treated
    let connective = locale.translate('rewards.multiobjective.and')

    if (completionExpression !== '') {
      // Lookup the objectiveSequence in the completion expression
      let index = -1

      let done = false

      // completionExpression comes from the missions system. The expression references
      // objective sequence numbers and are logically combined using 'and', 'or' and can
      // be grouped with parenthesis. Below are some sample expressions using an example
      // where there are 3 objectives, sequenced 1, 2 and 3.
      // '': equivalent to '1 and 2 and 3'
      // '1 or 2 or 3'
      // '1 or (2 and 3)'
      // '(1 and 2) or 3'
      while (!done) {
        index = completionExpression.indexOf(objectiveSequence, index + 1)

        // Make sure that the preceeding and following characters in the
        // completion expression are not numeric
        const previous
          = index - 1 >= 0
            ? parseInt(completionExpression.charAt(index - 1), 10)
            : NaN
        const next
          = index + 1 >= completionExpression.length
            ? NaN
            : parseInt(completionExpression.charAt(index + 1), 10)

        if (isNaN(previous) && isNaN(next)) {
          done = true
        }
      }

      // Search for an "or" after the found index
      const connectiveIndex = completionExpression.indexOf('or', index + 1)
      const connectiveKey
        = connectiveIndex >= 0
          ? 'rewards.multiobjective.or'
          : 'rewards.multiobjective.and'

      connective = locale.translate(connectiveKey)
    }
    return <div class="objective-joiner">{ connective }</div>
  }

  renderObjectives (objectives, completionExpression = '') {
    const { renderCompleted, renderAnimation } = this.props
    const isSingle = objectives.length === 1

    return (
      <div class="objectives">
        { objectives.map((objective, index) => (
          <div class="objective">
            <div
              class={ util.classNames(
                objective.isComplete ? 'completed' : 'active',
                'description',
                !renderCompleted && 'available',
                !isSingle && 'multiple'
              ) }
            />
            <div class="progress-info">
              { this.renderProgressBar(
                !renderAnimation && objective.isComplete,
                objective.completedSteps,
                objective.remainingSteps
              ) }
              { this.renderProgress(
                objective.completedSteps,
                objective.remainingSteps
              ) }
            </div>
            { this.renderConnective(
              completionExpression,
              index + 1,
              objectives.length === index + 1
            ) }
          </div>
        )) }
      </div>
    )
  }

  renderEntryBody () {
    const { item, renderCompleted } = this.props
    const loc = locale.get(true)
    const objectives = util.getDeepValue(this.props, 'item.objectives')
    const completionExpression = util.getDeepValue(
      this.props,
      'item.completionExpression'
    )

    if (objectives) {
      return (
        <div class="entry-body">
          <div class="title">{ item.title[loc] }</div>
          { this.renderObjectives(objectives, completionExpression) }
        </div>
      )
    }
    else {
      const classNames = util.classNames(
        renderCompleted ? 'completed' : 'active',
        'description'
      )

      return (
        <div class="entry-body">
          <div class="title">{ item.title[loc] }</div>
          <div class={ classNames }/>
          <div class="progress-info">
            { this.renderProgressBar(
              renderCompleted,
              item.completedSteps,
              item.remainingSteps
            ) }
            { this.renderProgress(item.completedSteps, item.remainingSteps) }
          </div>
        </div>
      )
    }
  }

  renderMissionEntry (iconUrl) {
    const { item, renderCompleted } = this.props

    return (
      <div class="full-entry">
        <div class="entry">
          { this.renderImage(renderCompleted, iconUrl) }
          { this.renderEntryBody() }
        </div>
        <div class="footer">
          { this.renderRewards(item.rewards, renderCompleted) }
          { renderCompleted || this.renderTimeLeft(item) }
        </div>
      </div>
    )
  }

  renderImage (renderCompleted, iconUrl) {
    return (
      <div
        class={ util.classNames(renderCompleted && 'completed', 'reward-image') }>
        { this.state.fallback && <Icon name="missionIconFallback"/> }
        { iconUrl && (
          <img
            class="icon"
            src={ iconUrl }
            onerror={ () => this.setFallbackIcon() }
          />
        ) }
      </div>
    )
  }

  render () {
    const { item, renderCompleted } = this.props

    if (!item) {
      return
    }
    const animate = this.getAnimateClass(item, item.isComplete, renderCompleted)
    const iconUrl = this.state.iconUrl

    return (
      <div
        class={ util.classNames(this.constructor.name, animate) }
        onanimationend={ (event) =>
          this.props.animationEndHandler
          && this.props.animationEndHandler(event)
        }>
        { this.renderMissionEntry(iconUrl) }
      </div>
    )
  }
}

export default RewardsMission
