import { Component, erasHeartbeater, Logger, $ } from 'lib'
import locale from 'Common/Locale/Locale'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import rewardsWatchHistory from 'Common/Service/Rewards/WatchHistory/RewardsWatchHistory'
import util from 'Common/Util/Util'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import {
  UNKNOWN_STATE,
  StatusTypes,
  Services,
  Icons,
  DropStatus,
  MissionStatus,
  HeartbeaterStatus
} from 'Component/Rewards/StatusInformer/RewardsStatusInformer.conf'

let savedState

// These two properties help determine how long to wait before missions
// credit is updated when a new live stream for a new game is being watched.
// This help prevent an unintended DDOS of Raptor when a new game transitions
// and everyone swarms to refresh watch history from Raptor in an effort
// to properly render the watch earned state
const watchHistoryRefreshBaseWait = 15 * util.times.MINUTES
const watchHistoryRefreshAdditionalWait = 6 * util.times.MINUTES

class RewardsStatusInformer extends Component {
  constructor (props) {
    super(props)

    this.log = new Logger(this.constructor.name)

    this.state = {
      showStatus: false,
      heartbeater: UNKNOWN_STATE,
      rewards: UNKNOWN_STATE,
      mission: UNKNOWN_STATE,
      drop: UNKNOWN_STATE
    }

    this.earnedMissionCreditTimeoutId = undefined

    // This component gets destroyed and recreated every time the
    // Stats Layout (Theater and  Sidebar modes) change losing its
    // current state. The heartbeater only emit states every minute
    // so we must keep the state between layout changes.
    if (savedState !== undefined) this.state = savedState
    else savedState = this.state // stores a reference to current state

    this.addListeners()
  }

  addListeners () {
    Object.keys(erasHeartbeater).forEach((key) => {
      const heartbeater = erasHeartbeater[key]

      heartbeater.addStateChangeListener((state) => {
        const newState = {
          heartbeater: this.getHeartbeaterStatus(state),
          mission: heartbeater.rewardability,
          drop: heartbeater.droppability
        }

        this.log.info(
          `Status changed heartbeater=${newState.heartbeater}`,
          `mission=${newState.mission} drop=${newState.drop}`
        )

        if (
          state === 'stopped'
          && this.state.heartbeater === 'unsupported_player'
        ) {
          // this is a no-op.
          // this block is to catch the case where a heartbeater (erasHeartbeater.youtubeHeartbeater in this case) gets
          // the `.detach(this.player)` function called on it. Inside the adapter's `detach` function,
          // `this.heartbeater.onStop()` is called at YoutubeAdapter.js#85 in shared-js. `onStop` returns a promise
          // that is NOT awaited in the YoutubeAdapter's `.detach` function, causing the state of 'stopped' to
          // asynchronously come in AFTER the UnsupportedPlayerHeartbeater's config and initial state gets set.
          // This block is here for `unsupported_player` states because those are synchronously and instantly
          // set right after initialization.
          // For more context, you can see: https://gh.riotgames.com/esports/ewp-web/pull/2322#discussion_r462329
        }
        else {
          this.setState(newState, () => {
            this.setEarnedMissionCredit()
          })
        }
      })
    })

    $(document).on(
      'click',
      this.clickListener = (event) => {
        const isBaseElement = !!$(event.target).closest(this.base).length

        if (this.state.showStatus && !isBaseElement) {
          this.setState({ showStatus: false })
        }
      }
    )
  }

  componentWillUnmount () {
    $(document).off('click', this.clickListener)
  }

  getHeartbeaterStatus (state) {
    // insert made up status that does not come back from heartbeater
    // if (riotBar.isRegionlessAccount()) return 'regionless_account'
    if (!riotBar.isLoggedIn()) return 'loggedout'

    return HeartbeaterStatus[state] ? state : StatusTypes.ERROR
  }

  toggleStatus () {
    this.setState({
      showStatus: !this.state.showStatus
    })
  }

  renderRewardsStatus () {
    const title = locale.translate('navigation.rewards')

    return this.renderStatus(Services.HEARTBEATER, title)
  }

  renderDropStatus () {
    const title = locale.translate('rewards.drops.label')

    return this.renderStatus(Services.DROP, title)
  }

  renderMissionStatus () {
    const title = locale.translate('rewards.passes.missions')

    return this.renderStatus(Services.MISSION, title)
  }

  renderStatus (name, title) {
    const { icon, message } = this.getStatus(name)

    return (
      <div class="status">
        <div class="status-icon">{ icon }</div>
        <div class="title">{ title }</div>
        <div class="message">{ message }</div>
      </div>
    )
  }

  componentDidUpdate (prevProps) {
    if (prevProps.gameId !== this.props.gameId) {
      // If we were already waiting to update earned mission credit, kill it
      // since a new game has started
      if (this.earnedMissionCreditTimeoutId !== undefined) {
        clearTimeout(this.earnedMissionCreditTimeoutId)
        this.earnedMissionCreditTimeoutId = undefined
      }

      const isWatchingLiveStream
        = watchUtil.streamTypes.live === this.props.watchType

      // If this is a live stream, set a timeout to set earned mission credit
      // a random time in the future to spread out the request load over a
      // period of time to avoid DDOSing Raptor.
      if (isWatchingLiveStream) {
        const randomMillis = Math.floor(
          Math.random() * watchHistoryRefreshAdditionalWait
        )
        const waitMillis = watchHistoryRefreshBaseWait + randomMillis

        this.earnedMissionCreditTimeoutId = setTimeout(() => {
          this.setEarnedMissionCredit(true)
          this.earnedMissionCreditTimeoutId = undefined
        }, waitMillis)
      }
      // If this is a vod, go ahead and check now
      else {
        this.setEarnedMissionCredit(true)
      }
    }
  }

  setEarnedMissionCredit (forceRefetch = false) {
    // This is temporary during Worlds 2021. Raptor has been getting overloaded and is
    // causing issues in the rest of the system. Since the Status Indicator for Missions content
    // has not been working already, we will default to green and not check for earned credit
    // **during live streams only**. VODs are still expected to query for Mission Credit
    const isWatchingLiveStream
      = watchUtil.streamTypes.live === this.props.watchType

    if (isWatchingLiveStream) {
      return Promise.resolve()
    }

    const missionsRewardability = this.state[Services.MISSION]

    // Check to make sure that missions is on for the given content
    // Added 'off_already_earned' in case we end up changing the internals of this further
    // such that if the status is 'off_already_earned', then getting the same status simply overwrites
    if (
      missionsRewardability === 'on'
      || missionsRewardability === 'off_already_earned'
    ) {
      return rewardsWatchHistory
        .fetchWatchHistory(this.props.tournamentId, forceRefetch)
        .then((watchedContent) => {
          const minutesWatched
            = util.getDeepValue(
              watchedContent[this.props.gameId],
              'entry_count'
            ) || 0

          if (minutesWatched >= rewardsWatchHistory.minutesWatchedThreshold) {
            this.setState({ mission: 'off_already_earned' })
          }
        })
        .catch((error) => {
          this.log.warn(error)
        })
    }
  }

  getStatus (name) {
    const status = this.state[name]

    if (name === Services.HEARTBEATER) {
      return HeartbeaterStatus[status] || HeartbeaterStatus.error
    }

    if (name === Services.MISSION) {
      return MissionStatus[status] || MissionStatus.error
    }

    if (name === Services.DROP) {
      return DropStatus[status] || DropStatus.error
    }

    this.log.error('Invalid status', status, 'for', name)
    return {}
  }

  getHighestSeverityIcon () {
    const heartbeaterStatus = this.getStatus(Services.HEARTBEATER)
    const missionStatus = this.getStatus(Services.MISSION)
    const dropStatus = this.getStatus(Services.DROP)

    // if the heartbeater is not OK it becomes the highest severity
    // as the other ones require this service to be working
    if (heartbeaterStatus.type !== StatusTypes.OK) return heartbeaterStatus.icon

    if (
      missionStatus.type === StatusTypes.ERROR
      || dropStatus.type === StatusTypes.ERROR
    ) {
      return Icons.ERROR
    }

    // Show the indicator as Green if either Missions or Rewards is healthy
    if (
      missionStatus.type === StatusTypes.OK
      || dropStatus.type === StatusTypes.OK
    ) {
      return Icons.OK
    }
    else {
      return Icons.WARN
    }
  }

  isStatusUnknown () {
    return (
      this.state.heartbeater === UNKNOWN_STATE
      && this.state.mission === UNKNOWN_STATE
      && this.state.drop === UNKNOWN_STATE
    )
  }

  isHeartbeaterError () {
    const { type } = this.getStatus(Services.HEARTBEATER)

    return type !== StatusTypes.OK
  }

  render () {
    // don't show status indicator until heartbeater give us back any other status
    if (this.isStatusUnknown()) return

    const statusItemsClasses = util.classNames(
      'status-items',
      this.state.showStatus ? 'show-status' : 'hide-status'
    )

    return (
      <div class={ this.constructor.name }>
        <div class={ statusItemsClasses }>
          { /* when there is a heartbeater issue, we only render
            that error as it is a prerequisite for missions and drops status */
            this.renderRewardsStatus() }
        </div>

        <div
          class="status-summary"
          onClick={ () => this.toggleStatus() }
          role="button">
          { locale.translate('navigation.rewards') }
          { this.getStatus(Services.HEARTBEATER).icon }
        </div>
      </div>
    )
  }
}

export default RewardsStatusInformer
