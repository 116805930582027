import locale from 'Common/Locale/Locale'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import Icon from 'Component/Asset/Icon/Icon'

export const UNKNOWN_STATE = 'unknown'

export const StatusTypes = {
  OK: 'ok',
  WARN: 'warn',
  ERROR: 'error',
  INFO: 'info'
}

export const Services = {
  HEARTBEATER: 'heartbeater',
  MISSION: 'mission',
  DROP: 'drop'
}

export const Icons = {
  OK: <Icon name="ok"/>,
  WARN: <Icon name="warn"/>,
  ERROR: <Icon name="error"/>,
  INFO: <Icon name="info"/>
}

export const DropStatus = {
  on: {
    type: StatusTypes.OK,
    icon: Icons.OK,
    message: locale.translate('rewards.status.drops.eligible')
  },
  off_stream_not_heartbeatable: {
    type: StatusTypes.WARN,
    icon: Icons.WARN,
    message: locale.translate('rewards.status.drops.notEligible')
  },
  off_tournament_not_configured: {
    type: StatusTypes.WARN,
    icon: Icons.WARN,
    message: locale.translate('rewards.status.drops.notEligible')
  },
  off_region_not_configured: {
    type: StatusTypes.WARN,
    icon: Icons.WARN,
    message: locale.translate('rewards.status.drops.notEligible')
  },
  // Means that the tournament/stream being watched is technically droppable, but
  // the stream is not yet live, so the heartbeater is waiting for the stream to go live before
  // actual heartbeating occurs. This is normal (OK) behavior and is not considered an error
  off_stream_not_live: {
    type: StatusTypes.OK,
    icon: Icons.OK,
    message: locale.translate('rewards.status.drops.eligible')
  },
  off_vod_stream: {
    type: StatusTypes.WARN,
    icon: Icons.WARN,
    message: locale.translate('rewards.status.drops.vods')
  },
  error: {
    type: StatusTypes.ERROR,
    icon: Icons.ERROR,
    message: locale.translate('rewards.status.drops.error')
  }
}

export const MissionStatus = {
  on: {
    type: StatusTypes.OK,
    icon: Icons.OK,
    message: locale.translate('rewards.status.missions.eligible')
  },
  off_tournament_not_configured: {
    type: StatusTypes.WARN,
    icon: Icons.WARN,
    message: locale.translate('rewards.status.missions.notEligible')
  },
  off_heartbeat_timestamp_not_in_range: {
    type: StatusTypes.WARN,
    icon: Icons.WARN,
    message: locale.translate('rewards.status.missions.notEligible')
  },
  off_stream_not_found: {
    type: StatusTypes.WARN,
    icon: Icons.WARN,
    message: locale.translate('rewards.status.missions.notEligible')
  },
  off_already_earned: {
    type: StatusTypes.WARN,
    icon: Icons.WARN,
    message: locale.translate('rewards.status.missions.earned')
  },
  // Means that the tournament/stream being watched is technically rewardable, but
  // the stream is not yet live, so the heartbeater is waiting for the stream to go live before
  // actual heartbeating occurs. This is normal (OK) behavior and is not considered an error
  off_stream_not_live: {
    type: StatusTypes.OK,
    icon: Icons.OK,
    message: locale.translate('rewards.status.missions.eligible')
  },
  error: {
    type: StatusTypes.ERROR,
    icon: Icons.ERROR,
    message: locale.translate('rewards.status.missions.error')
  }
}

export const HeartbeaterStatus = {
  heartbeating: {
    type: StatusTypes.OK,
    icon: Icons.OK,
    message: locale.translate('rewards.status.correct')
  },
  // Means that the tournament/stream being watched is technically rewardable, but
  // the stream is not yet live, so the heartbeater is waiting for the stream to go live before
  // actual heartbeating occurs. This is normal (OK) behavior and is not considered an error
  waiting: {
    type: StatusTypes.OK,
    icon: Icons.OK,
    message: locale.translate('rewards.status.correct')
  },
  // Means the player is paused/stopped which makes the heartbeater stop.
  // This is a normal (OK) behavior and is not considered an error
  stopped: {
    type: StatusTypes.OK,
    icon: Icons.OK,
    message: locale.translate('rewards.status.correct')
  },
  service_unavailable: {
    type: StatusTypes.ERROR,
    icon: Icons.ERROR,
    message: locale.translate('rewards.status.error')
  },
  unsupported_player: {
    type: StatusTypes.ERROR,
    icon: Icons.ERROR,
    message: locale.translate('rewards.status.unsupportedPlayer')
  },
  // This is a status we want to skip and let the Drop and Mission status.
  // render instead. We consider it type=OK
  not_heartbeatable: {
    type: StatusTypes.OK,
    icon: Icons.OK,
    message: locale.translate('rewards.status.correct')
  },
  loggedout: {
    type: StatusTypes.INFO,
    icon: Icons.INFO,
    message: locale.translate('rewards.status.earn', {
      action: (
        <a onclick={ () => riotBar.login(window.location.href) } class="link">
          { locale.translate('rewards.status.login') }
        </a>
      )
    })
  },
  optedout: {
    type: StatusTypes.INFO,
    icon: Icons.INFO,
    message: locale.translate('rewards.status.earn', {
      action: (
        <a href="/rewards" class="link">
          { locale.translate('rewards.status.optin') }
        </a>
      )
    })
  },
  regionless_account: {
    type: StatusTypes.ERROR,
    icon: Icons.ERROR,
    message: locale.translate('rewards.status.accountError', {
      learnMore: (
        <a href="/rewards" class="link">
          { locale.translate('rewards.status.learnMore') }
        </a>
      )
    })
  },
  error: {
    type: StatusTypes.ERROR,
    icon: Icons.ERROR,
    message: locale.translate('rewards.status.error')
  }
}
