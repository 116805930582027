import { Component } from 'lib'
import RewardsMission from 'Component/Rewards/Mission/RewardsMission'
import RewardsPassDescription from 'Component/Rewards/PassDescription/RewardsPassDescription'
import RewardsPassSummary from 'Component/Rewards/PassSummary/RewardsPassSummary'
import RewardsAchievement from 'Component/Rewards/Achievement/RewardsAchievement'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Image from 'Component/Asset/Image/Image'

// TODO Fix this. This should not be hardcoded
const TOTAL_MISSIONS = 8

class RewardsPasses extends Component {
  constructor (props) {
    super(props)
    const { activePasses } = props

    this.state = {
      selectedPass: activePasses && activePasses.length && activePasses[0]
    }
  }

  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.onPassSelectedHandler = (pass) =>
      this.setState({
        selectedPass: pass,
        accordionExpanded: false
      })

    this.onAccordionExpandedHandler = () =>
      this.setState({ accordionExpanded: !this.state.accordionExpanded })
  }

  renderPassColumn () {
    const { morePassesAvailable } = this.props

    return (
      <div class="pass-column">
        { this.renderPassSummaries() }
        <div class="passes-available">
          { morePassesAvailable && locale.translate('rewards.passes.addPass') }
        </div>
      </div>
    )
  }

  renderPassSummaries () {
    return this.props.activePasses.map((pass) => (
      <RewardsPassSummary
        columnItem
        expanded={ this.state.accordionExpanded }
        selected={
          util.getDeepValue(this.state, 'selectedPass.name')
          === (pass && pass.name)
        }
        activePass={ pass }
        onPassSelectedHandler={ this.onPassSelectedHandler }
      />
    ))
  }

  renderAccordion () {
    return (
      <div class="accordion">
        <RewardsPassSummary
          selected
          expanded={ this.state.accordionExpanded }
          activePass={ this.state.selectedPass }
          onPassSelectedHandler={ this.onAccordionExpandedHandler }
        />
      </div>
    )
  }

  renderMissionsLoading () {
    const passText = (
      <span class="pass-type">
        { locale.translate(
          this.props.isTeamPass
            ? 'rewards.passes.teamPass'
            : 'rewards.passes.fanPass'
        ) }
      </span>
    )

    return (
      <div class="missions-loading">
        <Image src="/rewards/missions-loading.png" class="image" size="150"/>
        <div class="title">
          { locale.translate('rewards.passes.loading.title') }
        </div>
        <div class="description">
          { locale.translate('rewards.passes.loading.description', {
            pass: passText
          }) }
        </div>
      </div>
    )
  }

  getTimeOffsetString (upcomingDateString) {
    const upcomingDate = new Date(upcomingDateString)

    let diffMS = upcomingDate.getTime() - Date.now()
    const daysUntilEvent = diffMS / util.times.DAY

    if (daysUntilEvent >= 1) {
      return locale.translate('date.days', { time: Math.floor(daysUntilEvent) })
    }

    // The upcoming date is less than one day in the future,
    // So calculate the hours and minutes until then
    const hoursDiff = diffMS / util.times.HOUR
    const hours = hoursDiff >= 0 ? Math.floor(hoursDiff) : 0

    // Subtract off the hours, leaving minutes, seconds, millis
    diffMS -= hours * util.times.HOUR
    const minutesDiff = diffMS / util.times.MINUTE
    const minutes = minutesDiff >= 0 ? Math.floor(minutesDiff) : 0

    // We now have the number of hours and minutes until the event
    // Format this into a string HH:MM:SS where SS is always 00
    const hhString = (hours < 10 ? '0' : '') + hours + ':'
    const mmString = (minutes < 10 ? '0' : '') + minutes + ':00'

    return hhString + mmString
  }

  calculatePassMissionDescription (activeLength, passType, allComplete) {
    const nextActiveMissionDate = util.getDeepValue(
      this.state,
      'selectedPass.passMissions.nextActiveMissionDate'
    )

    let passMissionDescription = ''

    if (activeLength === 0) {
      if (allComplete) {
        passMissionDescription = locale.translate(
          'rewards.passes.noMissionsSplit'
        )
      }
      else if (nextActiveMissionDate !== undefined) {
        passMissionDescription = locale.translate(
          'rewards.passes.noActiveMissions',
          {
            time: this.getTimeOffsetString(nextActiveMissionDate)
          }
        )
      }
    }
    else {
      passMissionDescription = locale.translate(
        'rewards.' + passType + '.missionComplete'
      )
    }
    return passMissionDescription
  }

  renderNoMissions (titleText, descriptionText, imgSrc) {
    return (
      <div class="no-missions">
        <Image class="img" src={ imgSrc } size="150"/>
        <div class="text-encouragement">{ locale.translate(titleText) }</div>
        <div class="message-body">{ locale.translate(descriptionText) }</div>
      </div>
    )
  }

  renderActiveMissions (allComplete, activeMissions, activeLength) {
    if (activeLength !== 0) {
      return (
        activeMissions
        && activeMissions.length !== 0 && (
          <RewardsMission
            item={ activeMissions[0] }
            key={ activeMissions[0].id }
            renderCompleted={ false }
          />
        )
      )
    }
    return (
      activeMissions
      && this.renderNoMissions(
        'rewards.noActive.title',
        allComplete ? 'rewards.noMissions' : 'rewards.noActiveMissions',
        '/rewards/no-weekly-missions.png'
      )
    )
  }

  renderAchievementLevelProgression () {
    const achievementLevels = util.getDeepValue(
      this.state,
      'selectedPass.passMissions.progression.levels'
    )

    if (!achievementLevels || achievementLevels.length === 0) {
      return <div class="pass-missions">{ this.renderMissionsLoading() }</div>
    }

    const activeMissions = util.getDeepValue(
      this.state,
      'selectedPass.passMissions.active'
    )
    const passType = this.props.isTeamPass ? 'teamPass' : 'fanPass'
    const activeLength = util.getDeepValue(
      this.state,
      'selectedPass.passMissions.active.length'
    )
    const missionCompleteCount = util.getDeepValue(
      this.state,
      'selectedPass.passMissions.progression.missionsCompleted'
    )
    const allComplete = missionCompleteCount === TOTAL_MISSIONS
    const passMissionDescription = this.calculatePassMissionDescription(
      activeLength,
      passType,
      allComplete
    )

    // TODO This only renders the first active mission. It is possible that there will
    // be multiple active missions. This should be fixed to render as many active
    // missions that exist
    return (
      <div class="pass-missions">
        <RewardsAchievement
          selectedPass={ this.state.selectedPass }
          isTeamPass={ this.props.isTeamPass }
          missionsExpiry={ this.props.missionsExpiry }
        />
        <div class="pass-mission-info">
          <div class="pass-mission-title">
            { locale.translate('rewards.' + passType + '.missionTitle', {
              league: this.state.selectedPass.name
            }) }
          </div>
          <div class="pass-mission-description">{ passMissionDescription }</div>
        </div>
        <div class="pass-mission">
          { this.renderActiveMissions(allComplete, activeMissions, activeLength) }
        </div>
      </div>
    )
  }

  render () {
    const { activePasses, showSummary } = this.props

    if (!activePasses || activePasses.length === 0) {
      return (
        <div class={ this.constructor.name }>
          <RewardsPassDescription isTeamPass={ this.props.isTeamPass }/>
        </div>
      )
    }

    return (
      <div class={ this.constructor.name }>
        { this.props.isTeamPass && this.renderAccordion() }
        { showSummary && this.renderPassColumn() }
        { this.renderAchievementLevelProgression() }
      </div>
    )
  }
}

export default RewardsPasses
