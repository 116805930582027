/* eslint max-lines: ["error", {"max": 400, "skipBlankLines": true, "skipComments": true}] */
import { Component, store, $, Logger } from 'lib'
import util from 'Common/Util/Util'
import RewardsBanner from 'Component/Rewards/Banner/RewardsBanner'
import InformDontTrackMe from 'Component/Inform/DontTrackMe/InformDontTrackMe'
import InformPassAvailability from 'Component/Inform/PassAvailability/InformPassAvailability'
import InformPassCelebration from 'Component/Inform/PassCelebration/InformPassCelebration'
import InformTeamPassSelector from 'Component/Inform/TeamPassSelector/InformTeamPassSelector'
import RewardsTopNav from 'Component/Rewards/TopNav/RewardsTopNav'
import RewardsDrops from 'Container/Rewards/Drops/RewardsDrops'
import rewardsTeamPersistence from 'Common/Service/Rewards/TeamPersistence/RewardsTeamPersistence'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import webSession from 'Common/Service/WebSession/WebSession'
import RewardsPasses from 'Component/Rewards/Passes/RewardsPasses'
import rewardsWatchStats from 'Common/Service/Rewards/WatchStats/RewardsWatchStats'

const viewedOverlayKey = 'rewards-viewed-pass-overlay'
const viewedPassCelebrationsKey = 'rewards-viewed-pass-celebrations'
const missionsExpiry = Date.parse('2019-09-19T06:59:59Z') // Summer Split mission expiration timestamp
// const showPasses = missionsExpiry - Date.now() > 0 // No pass (team/fan) tabs after Summer Split 2019

class RewardsLoggedIn extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.viewedPassOverlay
      = JSON.parse(window.localStorage.getItem(viewedOverlayKey)) || {}
    this.viewedPassCelebrations
      = JSON.parse(window.localStorage.getItem(viewedPassCelebrationsKey)) || {}
    this.puuid = riotBar.getPuuid()
    this.viewedPassOverlayByUser = this.viewedPassOverlay[this.puuid] || false
    this.viewedPassCelebrationsByUser
      = this.viewedPassCelebrations[this.puuid] || []
    this.addListeners()
  }

  addListeners () {
    // store.onChange(
    //   'userAccount',
    //   this.userAccountListener = (userAccount) => this.setState(userAccount)
    // )

    store.onChange(
      'riotBar.authChecked',
      this.authCheckedListener = () => {
        const authState = riotBar.getAuthState()

        if (authState) {
          this.setState(authState)
        }
      }
    )

    this.blurRemovalHandler = () => this.setState({ blurEnabled: false })

    this.fetchPassInfo = () =>
      rewardsTeamPersistence
        .fetchPassInfo()
        .then((passesInfo) => {
          const isFanPassOverride = !!util.getCookie('isFanPass') // Remove override when going to prod
          const isTeamPass
            = util.getDeepValue(passesInfo, 'active.team.availableForPurchase')
            && !isFanPassOverride
          const pass = util.getDeepValue(
            passesInfo,
            'active.' + (isTeamPass ? 'team' : 'fan')
          )
          const hasActionableTeamPasses
            = passesInfo.actionable && passesInfo.actionable.length > 0
          const hasActiveTeamOrFanPasses = pass.passes && pass.passes.length > 0
          const nextCelebratedAchievementLevel = this.getNextCelebratedAchievementLevel(
            pass.passes
          )
          const nextCelebratedPass
            = nextCelebratedAchievementLevel
            && this.getPassContainingAchievementLevel(
              pass.passes,
              nextCelebratedAchievementLevel
            )

          this.setState({
            actionableTeamPasses: passesInfo.actionable,
            activePasses: pass.passes,
            ownsRewardsPass:
              hasActionableTeamPasses || hasActiveTeamOrFanPasses,
            morePassesAvailable: pass.morePassesAvailable,
            isTeamPass: isTeamPass,
            passFetched: true,
            hasViewedPassOverlay: this.viewedPassOverlayByUser,
            celebratedAchievementLevel: nextCelebratedAchievementLevel,
            celebratedPass: nextCelebratedPass
          })
        })
        .catch((error) => {
          this.log.error(error)
        })

    this.onTeamPassSelected = () =>
      webSession
        .fetchPlayerEntitlements()
        .then(() => this.fetchPassInfo())
        .catch((error) => {
          this.log.error(error)
        })

    // store.onChange(
    //   'webSessionClient.ready',
    //   (this.onWebSessionReadyListener = (ready) => {
    //     if (ready && showPasses) {
    //       this.fetchPassInfo()
    //     }
    //   })
    // )

    this.watchStatsHandler = (watchStats) => this.setState({ watchStats })
    this.onTabSelectedHandler = (selectedTab) => this.setState({ selectedTab })

    this.onPassOverlayDismissed = () => {
      this.viewedPassOverlayByUser = true
      this.viewedPassOverlay[this.puuid] = this.viewedPassOverlayByUser

      window.localStorage.setItem(
        viewedOverlayKey,
        JSON.stringify(this.viewedPassOverlay)
      )
      this.setState({ hasViewedPassOverlay: true })
    }

    this.onCelebrationOverlayDismissed = () => {
      // First persist that we've celebrated this mission
      this.viewedPassCelebrationsByUser.push(
        this.state.celebratedAchievementLevel.id
      )
      this.viewedPassCelebrations[
        this.puuid
      ] = this.viewedPassCelebrationsByUser
      window.localStorage.setItem(
        viewedPassCelebrationsKey,
        JSON.stringify(this.viewedPassCelebrations)
      )

      // Now, determine if we have more celebrations to view
      const activePasses = this.state.activePasses
      const nextCelebratedAchievementLevel = this.getNextCelebratedAchievementLevel(
        activePasses
      )
      const nextCelebratedPass = this.getPassContainingAchievementLevel(
        activePasses,
        nextCelebratedAchievementLevel
      )

      this.setState({
        celebratedAchievementLevel: nextCelebratedAchievementLevel,
        celebratedPass: nextCelebratedPass
      })
    }

    this.afterCelebrationHandler = () => {
      !this.state.celebrationHandled
        && this.setState({ celebrationHandled: true })
    }
  }

  componentWillUnmount () {
    this.removeListeners()
    $(document.body).removeClass('no-scroll')
  }

  isFinalAchievementLevel (activePass, achievementLevel) {
    if (activePass == null || achievementLevel == null) {
      return false
    }

    return (
      activePass.passMissions.progression.levels.indexOf(achievementLevel)
      === activePass.passMissions.progression.levels.length - 1
    )
  }

  getNextCelebratedAchievementLevel (activePasses) {
    if (!activePasses || activePasses.length === 0) {
      return undefined
    }

    let achievementLevels = activePasses.map((activePass) =>
      activePass.passMissions.progression.levels.filter(
        (achievementLevel) =>
          achievementLevel.status === 'complete'
          && this.viewedPassCelebrationsByUser.indexOf(achievementLevel.id) === -1
      )
    )

    achievementLevels = achievementLevels.reduce(
      (
        accumulator,
        currentValue // This reduce flattens the array of arrays
      ) => accumulator.concat(currentValue),
      []
    )

    return achievementLevels.length ? achievementLevels[0] : undefined
  }

  getPassContainingAchievementLevel (activePasses, achievementLevel) {
    if (achievementLevel === undefined) {
      return undefined
    }

    return activePasses.find(
      (activePass) =>
        activePass.passMissions.progression.levels.indexOf(achievementLevel)
        > -1
    )
  }

  removeListeners () {
    // store.removeListener('userAccount', this.userAccountListener)
    store.removeListener('riotBar.authChecked', this.authCheckedListener)
    // store.removeListener(
    //   'webSessionClient.ready',
    //   this.onWebSessionReadyListener
    // )
  }

  componentDidUpdate () {
    if (
      this.shouldOpenTeamPassSelector()
      || this.shouldOpenPassAvailability()
      || this.shouldOpenPassCelebration()
    ) {
      $(document.body).addClass('no-scroll')
    }
    else {
      $(document.body).removeClass('no-scroll')
    }
  }

  shouldOpenPassAvailability () {
    return (
      this.state.passFetched
      && !this.state.hasViewedPassOverlay
      && !this.state.ownsRewardsPass
    )
  }

  shouldOpenPassCelebration () {
    return (
      this.state.passFetched
      && this.state.celebrationHandled
      && this.state.celebratedAchievementLevel
      && this.state.celebratedPass
      && !this.shouldOpenTeamPassSelector()
    )
  }

  shouldOpenTeamPassSelector () {
    return (
      this.state.celebrationHandled
      && this.state.actionableTeamPasses
      && this.state.actionableTeamPasses.length > 0
    )
  }

  fetchWatchStats () {
    return rewardsWatchStats
      .fetchWatchStats()
      .then((stats) => {
        if (stats.loggedOut) {
          this.log.info('Not Logged in')
        }
        this.watchStatsHandler(stats)
      })
      .catch((error) => {
        this.log.error(error)
      })
  }

  componentDidMount () {
    this.fetchWatchStats()
  }

  renderNavItems (topNavTabs) {
    const keys = Object.keys(topNavTabs)

    if (!this.state.selectedTab) {
      this.setState({ selectedTab: keys[0] })
    }

    return keys.map((tab) => {
      const selected = this.state.selectedTab === tab

      return (
        <div class={ util.classNames('nav-item', selected && 'selected') }>
          { topNavTabs[tab] }
        </div>
      )
    })
  }

  renderPassCelebration () {
    const isFinalAchievementLevel = this.isFinalAchievementLevel(
      this.state.celebratedPass,
      this.state.celebratedAchievementLevel
    )

    return (
      this.shouldOpenPassCelebration() && (
        <InformPassCelebration
          isTeamPass={ this.state.isTeamPass }
          isFinalAchievementLevel={ isFinalAchievementLevel }
          showConfetti={ isFinalAchievementLevel }
          onCelebrationOverlayDismissed={ this.onCelebrationOverlayDismissed }
          activePass={ this.state.celebratedPass }
          achievementLevel={ this.state.celebratedAchievementLevel }
        />
      )
    )
  }

  renderPassOverlay () {
    return (
      this.shouldOpenPassAvailability() && (
        <InformPassAvailability
          isTeamPass={ this.state.isTeamPass }
          onPassOverlayDismissed={ this.onPassOverlayDismissed }
        />
      )
    )
  }

  buildTopNav () {
    // Map locale keys to components
    const topNavTabs = {}

    // Order here matters for ordering the tabs
    topNavTabs['rewards.drops.label'] = <RewardsDrops/>

    if (this.state.passFetched) {
      const teamOrFanPassKey = this.state.isTeamPass
        ? 'rewards.passes.teamPass'
        : 'rewards.passes.fanPass'

      topNavTabs[teamOrFanPassKey] = (
        <RewardsPasses
          showSummary={ this.state.isTeamPass }
          activePasses={ this.state.activePasses }
          morePassesAvailable={ this.state.morePassesAvailable }
          key={ this.state.activePasses }
          isTeamPass={ this.state.isTeamPass }
          missionsExpiry={ missionsExpiry }
        />
      )
    }

    return topNavTabs
  }

  render () {
    const topNavTabs = this.buildTopNav()
    const tabList = Object.keys(topNavTabs)
    const classNames = util.classNames(
      this.constructor.name,
      this.state.blurEnabled && 'blur-enabled'
    )

    return (
      <div class={ classNames }>
        <RewardsBanner
          summonerName={ this.state.name }
          gamesWatchedCount={ util.getDeepValue(
            this.state,
            'watchStats.games_watched'
          ) }
          hoursWatchedCount={ util.getDeepValue(
            this.state,
            'watchStats.hours_watched'
          ) }
        />
        { tabList.length > 1 && (
          <RewardsTopNav
            tabs={ tabList }
            onTabSelectedHandler={ this.onTabSelectedHandler }
          />
        ) }
        { this.renderNavItems(topNavTabs) }
        { this.shouldOpenTeamPassSelector() && (
          <InformTeamPassSelector
            league={ this.state.actionableTeamPasses[0] }
            teamPassSelectedHandler={ this.onTeamPassSelected }
          />
        ) }
        <InformDontTrackMe/>
        { this.renderPassOverlay() }
        { this.renderPassCelebration() }
      </div>
    )
  }
}

export default RewardsLoggedIn
