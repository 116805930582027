import { Component } from 'lib'
import locale from 'Common/Locale/Locale'
import Icon from 'Component/Asset/Icon/Icon'
import Image from 'Component/Asset/Image/Image'

// Asset urls for LCS & LEC Team Pass Rewards and Fan Pass Rewards
const ASSET_URLS = {
  lcs: [
    '/rewards/pass/LCS-icon.png',
    '/rewards/pass/LCS-emote.png',
    '/rewards/pass/LCS-icon-lvl2.png',
    '/rewards/pass/LCS-ward.png',
    '/rewards/pass/LCS-dragonslayer-braum.png'
  ],
  lec: [
    '/rewards/pass/LEC-icon.png',
    '/rewards/pass/LEC-emote.png',
    '/rewards/pass/LEC-icon-lvl2.png',
    '/rewards/pass/LEC-ward.png',
    '/rewards/pass/LEC-dragonslayer-braum.png'
  ],
  fan: [
    '/rewards/pass/icon-pro-poro.png',
    '/rewards/pass/teampass-olaf-inventory.png',
    '/rewards/pass/icon-screemo.png',
    '/rewards/pass/fan-ward.png',
    '/rewards/pass/fan-dragonslayer-braum.png'
  ]
}

class InformPassRewards extends Component {
  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.showModal = () => {
      this.setState({ active: true })
    }

    this.hideModal = () => {
      this.setState({ active: false })
    }
  }

  render () {
    const iconURLS = ASSET_URLS[this.props.passType]

    return (
      <div class={ this.constructor.name }>
        <a class="link" onclick={ this.showModal }>
          { locale.translate('rewards.passes.viewRewards') }
        </a>
        { this.state.active && (
          <div class="modal">
            <div class="content">
              <table>
                <thead>
                  <tr>
                    <th class="modal-title">
                      { locale.translate('rewards.missionsCompleted') }
                    </th>
                    <th class="modal-title">
                      { locale.translate('rewards.passes.earned') }
                      <div class="close" onclick={ this.hideModal }>
                        <Icon name="bubbleClose"/>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1</th>
                    <th class="icon-rewards">
                      <Image
                        class="reward-image"
                        src={ iconURLS[0] }
                        size="100"
                      />
                      <span>+</span>
                      <Image
                        class="reward-image"
                        src={ iconURLS[1] }
                        size="100"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>3</th>
                    <th class="icon-rewards">
                      <Image
                        class="reward-image"
                        src={ iconURLS[2] }
                        size="100"
                      />
                      <span>+</span>
                      <div class="reward-image">
                        <Image
                          class="ward-image"
                          src={ iconURLS[3] }
                          size="100"
                        />
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>6</th>
                    <th class="chroma">
                      <Image
                        class="chroma-image"
                        src={ iconURLS[4] }
                        size="100"
                      />
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) }
      </div>
    )
  }
}

export default InformPassRewards
