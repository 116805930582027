import { Component, Logger, store, router } from 'lib'
import rewardsOpting from 'Common/Service/Rewards/Opting/RewardsOpting'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import RewardsStartEarning from 'Component/Rewards/StartEarning/RewardsStartEarning'
import util from 'Common/Util/Util'
import RewardsLoggedIn from 'Component/Rewards/LoggedIn/RewardsLoggedIn'
import InformLoading from 'Component/Inform/Loading/InformLoading'
// import InformRegionlessAccount from 'Component/Inform/RegionlessAccount/InformRegionlessAccount'

const optInKey = 'optIn'
const optInQueryParam = '?' + optInKey + '=true'

class Rewards extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  addListeners () {
    store.onChange(
      'rewards.optOut.success',
      this.optOutListener = (successStatus) => {
        successStatus
          ? this.fetchOptingState()
          : this.setState({ error: 'Opt out request failed.' })
      }
    )

    this.onOptClick = () => {
      this.optIn()
    }

    this.onLoginListener = () => {
      window.localStorage.setItem(optInKey, true)
      riotBar.login(
        window.location.href.replace(optInQueryParam, '') + optInQueryParam
      )
    }
  }

  componentDidMount () {
    this.fetchOptingState()
    this.addListeners()
    this.optInIfNecessary()
  }

  optInIfNecessary () {
    if (
      window.location.href.indexOf(optInQueryParam) > -1
      && window.localStorage.getItem(optInKey)
    ) {
      this.optIn()
    }
  }

  fetchOptingState () {
    return rewardsOpting
      .fetchOptingState()
      .then((optingState) => {
        this.setState({ optingState })
        return optingState
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  optIn () {
    const removeOptInQueryParam = () => {
      window.localStorage.removeItem(optInKey)

      const path = router.currentUrl.replace(optInQueryParam, '')

      router.redirect(path)
    }

    return rewardsOpting
      .optIn()
      .then(() => {
        store.set('rewards.optOut.success', true)
        removeOptInQueryParam()
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
        removeOptInQueryParam()
      })
  }

  componentWillUnmount () {
    this.removeListeners()
  }

  removeListeners () {
    store.removeListener('rewards.optOut.success', this.optOutListener)
    // store.removeListener(
    //   'webSessionClient.ready',
    //   this.onWebSessionReadyListener
    // )
    // store.removeListener('userAccount', this.userAccountListener)
  }

  renderRewardsOrError () {
    const isLoggedIn = riotBar.isLoggedIn()
    const isOptedOut = util.getDeepValue(this.state, 'optingState.isOptedOut')
    // const isRegionlessAccount = riotBar.isRegionlessAccount()

    if (isLoggedIn && isOptedOut === undefined) {
      return <InformLoading/>
    }

    // if (isRegionlessAccount) {
    //   return <InformRegionlessAccount />
    // } else
    if (isLoggedIn && !isOptedOut) {
      return <RewardsLoggedIn/>
    }
    else {
      return (
        <RewardsStartEarning
          loggedIn={ isLoggedIn }
          actionOnClickHandler={ isOptedOut ? this.optIn : this.onLoginListener }
        />
      )
    }
  }

  render () {
    const classes = util.classNames(
      this.constructor.name,
      this.state.blurEnabled && 'z-enabled'
    )

    return <main class={ classes }>{ this.renderRewardsOrError() }</main>
  }
}

export default Rewards
