import { Component } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'

class RewardsTopNav extends Component {
  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.onTabSelected = (tab) => {
      this.setState({ selectedTab: tab })
      this.props.onTabSelectedHandler(tab)
    }
  }

  render () {
    if (!this.state.selectedTab) {
      this.setState({ selectedTab: this.props.tabs[0] })
    }

    return (
      <div class={ this.constructor.name }>
        { this.props.tabs.map((tab) => (
          <div
            role="button"
            onclick={ () => this.onTabSelected(tab) }
            class={ util.classNames(
              'tab',
              this.state.selectedTab === tab && 'selected'
            ) }>
            { locale.translate(tab) }
          </div>
        )) }
      </div>
    )
  }
}

export default RewardsTopNav
