import { Component } from 'lib'
import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'

class RewardsBanner extends Component {
  round (stringValue) {
    const number = parseFloat(stringValue)

    return number === Number.NaN ? 0 : Math.round(number)
  }

  render () {
    return (
      <div
        class={ this.constructor.name }
        style={ Image.background('/rewards/finals_header.jpg') }>
        <div class="personal-text">
          <div class="username">{ this.props.summonerName }</div>
        </div>
        <div class="stats">
          { this.props.gamesWatchedCount !== undefined && (
            <div class="stat">
              <div class="number">{ this.props.gamesWatchedCount }</div>
              <div class="text">{ locale.translate('rewards.gamesWatched') }</div>
            </div>
          ) }
          { this.props.hoursWatchedCount !== undefined && (
            <div class="stat">
              <div class="number">
                { this.round(this.props.hoursWatchedCount) }
              </div>
              <div class="text">{ locale.translate('rewards.hoursWatched') }</div>
            </div>
          ) }
        </div>
      </div>
    )
  }
}

export default RewardsBanner
